import { ReactNode } from "react";

const AdahubGradientBackground = ({
  children,
  color = "orange",
}: {
  children: ReactNode;
  color?: "blue" | "orange";
}) => {
  if (color === "blue") {
    return (
      <div
        className={`bg-gradient-to-b from-[#E2E4F7] to-white h-[587px] w-full`}>
        {children}
      </div>
    );
  }

  return (
    <div
      className={`bg-gradient-to-b from-adahubGradient to-white h-[587px] w-full`}>
      {children}
    </div>
  );
};

export default AdahubGradientBackground;
