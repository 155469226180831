"use client"
import AdahubGradientBackground from "@/app/[locale]/(landingPage)/(homepageLayout)/home/components/AdahubGradientBackground";
import dynamic from "next/dynamic";
import React from "react";

const FooterNew = dynamic(
  () =>
    import(
      "@/app/[locale]/(landingPage)/(homepageLayout)/home/components/Footer-new"
    )
);

const LandingPageLayout = ({ children }: { children: React.ReactNode }) => {
  return (
    <>
      <div>
        <AdahubGradientBackground>{children}</AdahubGradientBackground>
      </div>
    </>
  );
};

export default LandingPageLayout;
